/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
	Authenticator,
	Button,
	Image,
	View,
	useAuthenticator,
	useTheme,
} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { I18n } from 'aws-amplify/utils';
import './rhymeAuthenticator.scss';
import React from 'react';
import RhymeLockup from '../../assets/rhyme_lockup.svg';
import { getAppEnv } from 'env-utilities';
import AmplifyAuthInterceptor from './AmplifyAuthInterceptor';
import { signIn, confirmSignIn, rememberDevice } from 'aws-amplify/auth';
import { Checkbox, FormControlLabel } from '@mui/material';
import Countdown, { zeroPad } from 'react-countdown';

I18n.putVocabulariesForLanguage('en', {
	'Sign in': 'SIGN IN', // Button label
	Email: 'Email Address *', // Username label
	Password: 'Password *', // Password label
});

type Props = {
	isRso?: boolean; //if true skip aws authenticator signin
	children: React.ReactNode;
};

function generateHeaderFooters(
	shouldRememberDevice,
	handleshouldRememberDevice,
	accessCodeCountdown
) {
	return {
		SignIn: {
			Header() {
				const { tokens } = useTheme();
				return (
					<View textAlign="center" padding={tokens.space.large}>
						<Image alt="Rhyme Logo and Company Name" src={RhymeLockup} />
					</View>
				);
			},
			Footer() {
				const { toForgotPassword } = useAuthenticator();
				return (
					<View textAlign="center">
						<Button fontWeight="normal" onClick={toForgotPassword} size="small" variation="link">
							Forgot your password?
						</Button>
						<br />
						<Button
							marginBottom={20}
							fontWeight="normal"
							onClick={goToPortal}
							size="small"
							variation="link"
						>
							Looking for Rhyme Case Search?
						</Button>
						<p>
							Rhyme Portal: {process.env.REACT_APP_ENV} - {process.env.REACT_APP_ADO_BUILD_NUMBER}
						</p>
					</View>
				);
			},
		},
		ConfirmSignIn: {
			Header() {
				const { challengeName } = useAuthenticator();
				return (
					<View>
						{challengeName == 'SOFTWARE_TOKEN_MFA' ? (
							<span>Enter your authenticator code:</span>
						) : (
							<span>
								Rhyme has implemented Multifactor Authentication to enhance your account security.
								An access code has been sent to your email on file. Please enter that code below and
								click confirm.
							</span>
						)}
					</View>
				);
			},
			Footer() {
				return (
					<View>
						<Countdown
							date={accessCodeCountdown}
							renderer={({ minutes, seconds, completed }) => {
								if (completed) {
									return (
										<View textAlign="center">
											<span>
												Your access code has expired. Please click &#39;Back to Sign In&#39; and
												re-sign in for a new code
											</span>
										</View>
									);
								} else {
									return (
										<View textAlign="center">
											<div>
												Access Code expires in {zeroPad(minutes)}:{zeroPad(seconds)}
											</div>
										</View>
									);
								}
							}}
						/>
						<FormControlLabel
							control={
								<Checkbox
									checked={!!shouldRememberDevice}
									onChange={handleshouldRememberDevice}
									color="primary"
									disableRipple
								/>
							}
							label={
								<span style={{ fontSize: '.8em' }}>
									Skip this step in the future if this is your private device.
								</span>
							}
						/>
					</View>
				);
			},
		},
	};
}

function generateServiceOverrides(
	interceptor: AmplifyAuthInterceptor,
	shouldRememberRef,
	setAccessCodeCountdown
) {
	return {
		handleSignIn(input) {
			interceptor.Activate();
			return signIn(input).then(async (output) => {
				if (output.isSignedIn) {
					interceptor.Deactivate();
				} else {
					// If multiple MFAs are enabled, this shows up after login.
					// Theres only email and software token, so we prefer software token if it is set up
					if (output.nextStep.signInStep == 'CONTINUE_SIGN_IN_WITH_MFA_SELECTION') {
						const confirmOutput = await confirmSignIn({
							challengeResponse: 'TOTP',
						});
						return confirmOutput;
					} else {
						setAccessCodeCountdown(Date.now() + 600000);
					}
				}
				return output;
			});
		},
		async handleConfirmSignIn(input) {
			return confirmSignIn(input).then(async (output) => {
				if (shouldRememberRef.current) {
					await rememberDevice();
				}
				interceptor.Deactivate();
				return output;
			});
		},
	};
}

const appEnv = getAppEnv();

const goToPortal = () => {
	window.open(`https://${appEnv}.priorauthnow.com/`, '_blank');
};

export function RhymeAuthenticator(props: Props) {
	const { children, isRso = false } = props;
	const interceptor = new AmplifyAuthInterceptor();
	window.addEventListener('beforeunload', () => {
		interceptor.Deactivate();
	});
	const shouldRememberRef = React.useRef(false);
	const [shouldRememberDevice, setShouldRememberDevice] = React.useState(false);
	const [accessCodeCountdown, setAccessCodeCountdown] = React.useState(0);
	const handleShouldRememberDevice = (event: React.ChangeEvent<HTMLInputElement>) => {
		setShouldRememberDevice(event.target.checked);
		shouldRememberRef.current = event.target.checked;
	};

	return (
		<>
			{isRso ? (
				children
			) : (
				<Authenticator
					hideSignUp
					components={generateHeaderFooters(
						shouldRememberDevice,
						handleShouldRememberDevice,
						accessCodeCountdown
					)}
					loginMechanisms={['email']}
					services={generateServiceOverrides(
						interceptor,
						shouldRememberRef,
						setAccessCodeCountdown
					)}
				>
					{children}
				</Authenticator>
			)}
		</>
	);
}
