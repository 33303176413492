import {
	Box,
	FormControlLabel,
	IconButton,
	Switch,
	TextField,
	Typography,
	Button,
} from '@mui/material';
import React, { FC, useState } from 'react';
import { useAppSelector } from '@app/hooks/useReduxToolkit';
import { clearUserMfaPreference, verifyTotpCode } from 'user-utilities';
import { QRCodeCanvas } from 'qrcode.react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { SnackbarManager } from 'rhyme-component-library';
import { UserState } from 'state-services';

type Props = {
	preferredMfa: string;
	seedCode: string;
	setOpen: (value: boolean) => void;
	setPreferredMfa: (value: string) => void;
};

const SecurityMenu: FC<Props> = ({ preferredMfa, seedCode, setOpen, setPreferredMfa }) => {
	const { userDetails } = useAppSelector<UserState>((state) => state.user);
	const [code, setCode] = useState('');

	const isNoMfaPreferred = (): boolean => {
		return preferredMfa === 'DISABLED';
	};
	const [checked, setChecked] = useState(!isNoMfaPreferred());

	const turnMfaOnInstructions =
		'To set up MFA, you must first set the MFA status to Opted-in by clicking the toggle above.';
	const qrCodeInstructions =
		'To set up MFA security for your account, scan the QR code below with a multi-factor authentication app.';
	const longCodeInstructions =
		'If you have trouble scanning the QR code, you may use the code below to configure your authentication app manually.';
	const challengeCodeInstructions =
		'Once your authentication app is set up, enter the 6 digit code it displays in the box below.';
	const optedInMessage = 'You have enabled and set up MFA for your account.';

	const saveClickHandler = async (): Promise<void> => {
		if (checked && isNoMfaPreferred()) {
			const result = await verifyTotpCode(code);
			if (result) {
				setPreferredMfa('SOFTWARE_TOKEN_MFA');
				SnackbarManager.show({
					message: 'MFA sign up successful!',
					type: 'success',
				});
			} else {
				SnackbarManager.show({
					message: 'MFA sign up failed!',
					type: 'error',
				});
			}
			setOpen(false);
		} else if (!checked && !isNoMfaPreferred()) {
			await clearUserMfaPreference();
			setPreferredMfa('DISABLED');
			SnackbarManager.show({
				message: 'MFA setting cleared!',
				type: 'success',
			});
			setOpen(false);
		}
	};

	const codeChangeHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setCode(event.target.value);
	};

	const optInOutChangeHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setChecked(event.target.checked);
	};

	const getQrCodeUrl = (secretCode: string): string => {
		return (
			'otpauth://totp/AWSCognito:' +
			userDetails.email +
			'?secret=' +
			secretCode +
			'&issuer=' +
			'Cognito'
		);
	};

	const handleCopyClick = (): void => {
		navigator.clipboard.writeText(seedCode);
	};

	const shouldMfaSaveButtonDisable = (): boolean => {
		const result =
			(!checked && isNoMfaPreferred()) ||
			(checked && isNoMfaPreferred() && !(code.length === 6)) ||
			(checked && !isNoMfaPreferred());
		return result;
	};

	return (
		<>
			<Typography fontWeight={'bold'} variant="subtitle2">
				{'MFA Status:       '}
				<FormControlLabel
					sx={{ marginLeft: 0.5 }}
					control={<Switch size="small" onChange={optInOutChangeHandler} checked={checked} />}
					label={checked ? 'Opted-In' : 'Opted-Out'}
				/>
			</Typography>
			<br />
			{isNoMfaPreferred() && checked ? (
				<div>
					<Typography fontWeight={'bold'} variant="subtitle2">
						One-time Password Seed:
					</Typography>
					<Typography sx={{ justifyContent: 'center', display: 'flex' }} variant="caption">
						{qrCodeInstructions}
					</Typography>
					<Box sx={{ justifyContent: 'center', display: 'flex' }}>
						<QRCodeCanvas size={250} value={getQrCodeUrl(seedCode)}></QRCodeCanvas>
					</Box>
					<Typography marginTop={0.5} variant="caption">
						{longCodeInstructions}
					</Typography>
					<div
						style={{
							justifyContent: 'center',
							display: 'flex',
							flex: 'block',
						}}
					>
						<Typography marginTop={0.5} fontWeight={700} variant="subtitle1">
							{seedCode}
						</Typography>
						<IconButton size="small" onClick={handleCopyClick} aria-label="copy">
							<ContentCopyIcon fontSize="inherit" />
						</IconButton>
					</div>
					<br />
					<Typography variant="caption">{challengeCodeInstructions}</Typography>
					<Typography fontWeight={'bold'} variant="subtitle2">
						Enter your 6-digit code:
					</Typography>
					<TextField label="MFA Code" size="small" onChange={codeChangeHandler}></TextField>
				</div>
			) : checked ? (
				<Typography variant="subtitle2">{optedInMessage}</Typography>
			) : (
				<Typography variant="subtitle2">{turnMfaOnInstructions}</Typography>
			)}
			<div color="primary" style={{ justifyContent: 'flex-end', display: 'flex' }}>
				<Button disabled={shouldMfaSaveButtonDisable()} onClick={saveClickHandler}>
					Save
				</Button>
			</div>
		</>
	);
};

export default SecurityMenu;
