/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export default class AmplifyAuthInterceptor {
	private origFetch;
	constructor() {
		({ fetch: this.origFetch } = window);
	}

	// This is an amplify stopgap to handle no EMAIL MFA support currently for aws amplify. This intercepts the calls from Amplify to
	// cognito. It replaces incoming EMAIL_OTP requests with SMS_MFA to show the SMS_MFA screen which is essentially the same screen the
	// library needs but does not yet support. It then intercepts the outgoing call and replaces the request object with what the cognito
	// backend wants to see for EMAIL OTP challenges. Activating this interceptor simply overwrites the fetch function
	public Activate() {
		window.fetch = async (...args) => {
			const [url, requestObj] = args;
			let newRequest;
			if (!!requestObj && requestObj.body && requestObj.body.toString().includes('SMS_MFA')) {
				const parsedBody = JSON.parse(requestObj.body?.toString());
				const newBody = {
					...parsedBody,
					ChallengeName: 'EMAIL_OTP',
					ChallengeResponses: {
						EMAIL_OTP_CODE: parsedBody.ChallengeResponses.SMS_MFA_CODE,
						USERNAME: parsedBody.ChallengeResponses.USERNAME,
					},
				};
				newRequest = { ...requestObj, body: JSON.stringify(newBody) };
			}
			const response: Response = newRequest
				? await this.origFetch(...[url, newRequest])
				: await this.origFetch(...args);

			let bodyFound;
			await response
				.clone()
				.json()
				.then((data) => {
					if (data?.ChallengeName == 'EMAIL_OTP') {
						bodyFound = data;
						bodyFound.ChallengeName = 'SMS_MFA';
						return bodyFound;
					}
				})
				// eslint-disable-next-line no-console
				.catch((err) => console.error(err));

			return !bodyFound
				? response
				: new Response(
						JSON.stringify({
							...bodyFound,
						})
					);
		};
	}

	public Deactivate() {
		window.fetch = this.origFetch;
	}
}
