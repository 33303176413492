import { Box } from '@mui/material';
import { useAppDispatch, useAppSelector } from '@app/hooks/useReduxToolkit';
import { FC, useEffect } from 'react';
import LighthouseSkeletonLoaderAndErrorAlert from './LighthouseSkeletonLoaderAndErrorAlert';
import { EntityType, PartnerEntity } from 'type-declarations';
import LighthouseApplication from './LighthouseApplication';
import { closeLighthouse, getLighthouseEmbedUrl } from 'state-services';
import './styles/lighthouse.scss';
import { isStatusIdleOrPending } from 'slice-utilities';
import { UserCapabilities } from '@app/shared/constants';
import { CustomMessage } from 'rhyme-component-library';

const Lighthouse: FC = () => {
	const dispatch = useAppDispatch();
	const { activeEntity } = useAppSelector((state) => state.user);
	const { partners, status: partnerStatus } = useAppSelector((state) => state.partner);
	const { entities } = useAppSelector((state) => state.user);

	const isLoadingLighthouseDependencies = (): boolean => {
		return !partners || !activeEntity || isStatusIdleOrPending([partnerStatus]);
	};

	//Handle tab closed -- update Sentry tracking.
	window.addEventListener('beforeunload', () => {
		dispatch(closeLighthouse());
	});
	//Handle navigating away from Lighthouse -- update Sentry tracking.
	useEffect(
		() => (): void => {
			dispatch(closeLighthouse());
		},
		[]
	);

	const filterOrgsWithDashboardAccess = (
		providerOrganizations: Array<PartnerEntity>
	): Array<PartnerEntity> => {
		return providerOrganizations.filter((p) =>
			entities.some(
				(e) =>
					e.entityId === p.id && e.capabilities.includes(UserCapabilities.GetLighthouseDashboard)
			)
		);
	};

	useEffect(() => {
		if (activeEntity) {
			let selectedPartnerEntities: Array<PartnerEntity> = [];
			const isProviderOrganization = activeEntity.partnerType === EntityType.PROVIDER_ORGANIZATION;
			if (isProviderOrganization) {
				const providerOrganizations = partners.filter(
					(p) => p.partnerType === EntityType.PROVIDER_ORGANIZATION && p.isLighthouseEnabled
				);
				selectedPartnerEntities = filterOrgsWithDashboardAccess(providerOrganizations);
			} else {
				selectedPartnerEntities = [activeEntity];
			}
			if (selectedPartnerEntities.length > 0) {
				dispatch(getLighthouseEmbedUrl({ activeEntity, selectedPartnerEntities }));
			}
		}
	}, [activeEntity]);

	return (
		<Box id="lighthouse-dashboard" sx={{ p: { xs: 2, sm: 3 } }}>
			{!activeEntity.isLighthouseEnabled ? (
				<CustomMessage
					title="Coming soon..."
					subtitle="Please reach out to your Rhyme Relationship Manager to enable access for your organization."
				/>
			) : isLoadingLighthouseDependencies() ? (
				<LighthouseSkeletonLoaderAndErrorAlert />
			) : (
				<LighthouseApplication
					activeEntity={activeEntity}
					partners={filterOrgsWithDashboardAccess(partners)}
				/>
			)}
		</Box>
	);
};

export default Lighthouse;
