import React from 'react';
import { Box, Snackbar } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { MyProps, MyState, Snackbar as SnackBar } from 'type-declarations';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export class SnackbarManager extends React.Component<MyProps, MyState> {
	static instance: SnackbarManager;
	constructor(props: MyProps) {
		super(props);
		SnackbarManager.instance = this;
	}

	/**
	 * Trigger a new snackbar to display by importing static method and passing a snackbar object.
	 * @example SnackbarManager.show({ message: 'Look a dynamic snackbar!' });
	 */
	static show(snackbar: SnackBar): void {
		SnackbarManager.instance.setState({ snackbar: snackbar });
	}
	static showBeforeNavigation(
		message: string,
		type: 'success' | 'info' | 'warning' | 'error' | undefined
	): void {
		setTimeout(() => {
			SnackbarManager.show({
				message,
				type,
			});
		}, 1);
	}
	/**
	 * When creating a customAction(@see defaultAction below) to override the basic "X" close button,
	 * use this method to close the snackbar. The custom action can be used to modify the snackbars
	 * appearance & close behavior.
	 * @example: <Button onClick={ SnackbarManager.close }>Close Me</Button>
	 * @example SnackbarManager.show({ message: 'Look a dynamic snackbar!', action: customAction });
	 */
	static close(): void {
		SnackbarManager.instance.setState({ snackbar: null });
	}
	// Handles closing with default action
	handleClose(): void {
		SnackbarManager.instance.setState({ snackbar: null });
	}

	defaultAction = (
		<React.Fragment>
			<IconButton size="small" aria-label="close" color="inherit" onClick={this.handleClose}>
				<CloseIcon fontSize="small" />
			</IconButton>
		</React.Fragment>
	);

	render(): React.ReactNode {
		return (
			<Box sx={{ height: '0', width: '0' }}>
				{this.state?.snackbar ? (
					<Snackbar
						anchorOrigin={
							this.state.snackbar.anchor
								? this.state.snackbar.anchor
								: { vertical: 'bottom', horizontal: 'center' }
						}
						autoHideDuration={this.state.snackbar.duration ? this.state.snackbar.duration : 2500}
						open={this.state.snackbar ? true : false}
						onClose={this.handleClose}
					>
						<Alert
							action={this.state.snackbar?.action ? this.state.snackbar.action : this.defaultAction}
							onClose={this.handleClose}
							severity={this.state.snackbar.type ? this.state.snackbar.type : 'info'}
							sx={{ width: '100%' }}
						>
							{this.state.snackbar.message || ''}
						</Alert>
					</Snackbar>
				) : (
					''
				)}
			</Box>
		);
	}
}
