import { ScopeKeyOptions, User, UserRole } from 'type-declarations';

export type UserRolesFilter = (userRoles: UserRole[]) => UserRole[];

export const removeGlobalRoles: UserRolesFilter = (userRoles: UserRole[]) =>
	userRoles.filter((e: UserRole) => Object.keys(e.scope).length !== 0);

export const removeProviderRsoRoles: UserRolesFilter = (userRoles: UserRole[]) =>
	userRoles.filter((e: UserRole) => e.name !== 'ProviderRsoPaRequester');

export const onlyProviderOrganizationRolesWithGetCase: UserRolesFilter = (userRoles: UserRole[]) =>
	userRoles.filter(
		(e: UserRole) =>
			Object.keys(e.scope).length > 0 &&
			Object.keys(e.scope)[0] === ScopeKeyOptions.ORGANIZATION_ID &&
			e.capabilities.includes('GetCase')
	);

export const filterUserRoles = (user: User, userRoleFilters?: UserRolesFilter[]): void => {
	if (userRoleFilters) {
		userRoleFilters.forEach((filter) => (user.userRoles = filter(user.userRoles)));
	}
};
