import * as services from 'state-services';
import { logOut } from 'state-services';
import { combineReducers } from '@reduxjs/toolkit';
import { State } from './rootState';
import { AllActions } from './rootAction';

/**
 * removes all saved state when user logout
 * @param state
 * @param action
 * @returns empty state
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const rootReducer = (state: State | undefined, action: AllActions) => {
	if ('type' in action && action.type === logOut.type) {
		state = undefined;
	}
	return allReducers(state, action);
};

/**
 * rootReducers
 * combining all reducers
 */
const allReducers = combineReducers({
	user: services.userReducer,
	admin: services.combineAdminReducers,
	partner: services.partnerReducer,
	lighthouse: services.lighthouseReducer,
});
