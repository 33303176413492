import SidebardNav from '@app/shared/components/sidebarNav/SidebarNav';
import { Outlet } from 'react-router-dom';
import { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import {
	SnackbarManager,
	SessionTimer,
	SessionTimerNotification,
	CustomMessage,
} from 'rhyme-component-library';
import { useAppSelector, useAppDispatch } from '@app/hooks/useReduxToolkit';
import {
	fetchUserRoles,
	removeGlobalRoles,
	removeProviderRsoRoles,
	fetchAndSetIdToken,
} from 'state-services/src/user';

//This component initializes needed resources for the react-portal application.
//While resources are loading, a Loading screen is displayed. Once all resources
//have loaded, the application is displayed to the user.
const ApplicationWrapper: FC = () => {
	const { id: activeEntityId } = useAppSelector((state) => state.user.activeEntity);
	//if the ID is not set on the active entity object, we have not
	//yet loaded the partner object and should continue showing the
	//Loading screen.
	const isLoading = !activeEntityId;
	const dispatch = useAppDispatch();

	const [showModal, setShowModal] = useState(SessionTimer.getShowModal());
	useEffect(() => {
		dispatch(fetchAndSetIdToken());
		dispatch(fetchUserRoles([removeGlobalRoles, removeProviderRsoRoles]));
		// Start timer when App mounts
		SessionTimer.startSessionTimer();

		// Add a listener to the SingletonService to update showModal state
		const showModalChangeListener = (newShowModal: boolean): void => {
			setShowModal(newShowModal);
		};
		SessionTimer.addShowModalChangeListener(showModalChangeListener);

		// Clean up the listener when the component unmounts
		return (): void => {
			SessionTimer.removeShowModalChangeListener(showModalChangeListener);
		};
	}, []);

	return isLoading ? (
		<CustomMessage title="Loading..." loading={isLoading} />
	) : (
		<Box
			sx={{ display: 'flex', width: '100%', height: '100%' }}
			onMouseMove={(): void => SessionTimer.resetSessionTimer()}
			onKeyUp={(): void => SessionTimer.resetSessionTimer()}
		>
			<SidebardNav />
			<Box component="main" sx={{ width: '100%', flexGrow: 1, height: '100%' }}>
				<Outlet />
			</Box>
			<SessionTimerNotification showModal={showModal} />
			<SnackbarManager />
		</Box>
	);
};

export default ApplicationWrapper;
