import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@app/store';

/**
 * wrapper around "useDispatch" hook that know the RootState type implicity
 * @returns the wrapped hook
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useAppDispatch = () => useDispatch<AppDispatch>();

/**
 * wrapper around "useSelector" hook that know the RootState type implicity
 * @returns the wrapped hook
 */
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
