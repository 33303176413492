/* eslint-disable @typescript-eslint/explicit-function-return-type */
import * as z from 'zod';

export function getUserFormSchema(approvedEmailDomains: Array<string>) {
	const userFormSchema = z.object({
		firstName: z.string().min(1, 'First name is required.'),
		lastName: z.string().min(1, 'Last name is required.'),
		roles: z.string().array().nonempty('At least one role is required.'),
		userName: z
			.string()
			.min(1, 'Email address is required.')
			.email('Please enter a valid email address.')
			.refine(
				(val) =>
					//If the Approved Email Domains list length is less than one, we will
					//not validate the domain and will accept any domain.
					//If the Approved Email Domains list has any items in it, we will
					//validate that the email field ends with one of those domains.
					approvedEmailDomains.length < 1 ||
					approvedEmailDomains.some((domain) => val.endsWith(domain)),
				`Must end with one of these domain(s): ${approvedEmailDomains.join(', ')}`
			),
	});

	return userFormSchema;
}
