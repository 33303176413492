import { createContext } from 'react';
import { Transaction, Span } from '@sentry/types';
import * as Sentry from '@sentry/react';
import {
	DashboardDetails,
	SentryIoTransactionDetails,
	SentryIoTransactionErrorDetails,
} from 'type-declarations';
import { v4 as uuidv4 } from 'uuid';
//********************************************************************
// note: dont update depreciated warnings new version will brake the
// current way we use sentry transactions
//********************************************************************

export function startLighthouseTransaction(
	portalSessionId: string,
	pageSessionId: string
): Transaction {
	const transaction = Sentry.startTransaction({
		name: 'Lighthouse Session',
	});
	transaction.setTag('portal_session_id', portalSessionId);
	transaction.setTag('page_session_id', pageSessionId);
	transaction.setTag('page_session_start', new Date().toISOString());
	return transaction;
}

export function finishTransaction(transaction: Transaction, status: string): void {
	transaction.setTag('page_session_end', new Date().toISOString());
	transaction.status = status;
	transaction.finish();
}

export function startLighthouseDashboardSpan(
	transaction: Transaction,
	dashboard: DashboardDetails
): Span {
	Sentry.getCurrentHub().configureScope((scope: { setSpan: (arg0: Transaction) => any }) =>
		scope.setSpan(transaction)
	);
	const span = transaction.startChild({
		op: 'ui.action',
		description: `Opened ${dashboard.tabText} dashboard.`,
	});
	span.setTag('dashboard_id', dashboard.dashboardId);
	span.setTag('dashboard_type', dashboard.tabText);
	return span;
}

export function endSpan(span: Span): void {
	span.finish();
}

export function recordSpan(transaction: Transaction, spanDescription: string): void {
	try {
		Sentry.getCurrentHub().configureScope((scope) => scope.setSpan(transaction));
		const span = transaction.startChild({
			op: 'ui.action',
			description: spanDescription,
		});
		span.finish();
	} catch (err) {
		// eslint-disable-next-line no-console
		console.warn(err);
	}
}

export function startTransaction(
	transactionDetails: SentryIoTransactionDetails,
	initialSpanDescription: string
): Transaction {
	const transaction = Sentry.startTransaction({
		name: transactionDetails.title,
	});
	transaction.setTag(SentryTags.INTERACTIONID, uuidv4());
	transaction.setTag(SentryTags.STEPTYPE, transactionDetails.stepType);
	transaction.setTag(SentryTags.INTERACTIONTYPE, transactionDetails.interactionType);
	transaction.setTag(SentryTags.STEPSTART, new Date().toISOString());
	transaction.setTag(SentryTags.USERABANDONED, false);
	transaction.setTag(SentryTags.USERATTEMPTED, false);
	transaction.setTag(SentryTags.COULDCONTINUE, false);
	transaction.setTag(SentryTags.ERRORID, '');
	transaction.setTag(SentryTags.HASUIERROR, false);
	recordSpan(transaction, initialSpanDescription);
	return transaction;
}

export function completeTransaction(
	transaction: Transaction,
	status: string,
	finalSpan: string
): void {
	try {
		recordSpan(transaction, finalSpan);
		transaction.setTag(SentryTags.STEPCOMPLETE, new Date().toISOString());
		transaction.status = status;
		transaction.finish();
	} catch (err) {
		// eslint-disable-next-line no-console
		console.warn(err);
	}
}

export function setUserAbandonded(transaction: Transaction, userAbandoned: boolean): void {
	if (transaction && transaction.setTag) {
		transaction.setTag(SentryTags.USERABANDONED, userAbandoned);
	}
}

export function setUserAttempted(userAttempted: boolean, transaction?: Transaction): void {
	if (transaction && transaction.setTag) {
		transaction.setTag(SentryTags.USERATTEMPTED, userAttempted);
	}
}

export function setCouldContinue(couldContiune: boolean, transaction?: Transaction): void {
	if (transaction && transaction.setTag) {
		transaction.setTag(SentryTags.COULDCONTINUE, couldContiune);
	}
}

export function setHasUiError(hasUiError: boolean, transaction?: Transaction): void {
	if (transaction && transaction.setTag) {
		transaction.setTag(SentryTags.HASUIERROR, hasUiError);
	}
}

export function setErrorId(errorId: string, transaction?: Transaction): void {
	if (transaction && transaction.setTag) {
		transaction.setTag(SentryTags.ERRORID, errorId);
	}
}

export function setErrorIds(errorIds: Array<string>, transaction?: Transaction): void {
	if (transaction && transaction.setTag) {
		transaction.setTag(SentryTags.ERRORIDS, errorIds.join(','));
	}
}

export function startErrorTransaction(
	transactionDetails: SentryIoTransactionErrorDetails,
	initialSpanDescription: string
): Transaction {
	const transaction = Sentry.startTransaction({
		name: transactionDetails.name,
	});
	transaction.setTag(SentryTags.ERRORNAME, transactionDetails.name);
	transaction.setTag(SentryTags.ERRORID, uuidv4());
	transaction.setTag(SentryTags.INTERACTIONTYPE, transactionDetails.interactionType);
	transaction.setTag(SentryTags.ERRORDATETIME, new Date().toISOString());
	transaction.setTag(SentryTags.ERRORMESSAGE, transactionDetails.message);
	transaction.setTag(
		SentryTags.ERRORSTACK,
		JSON.stringify(transactionDetails.stack).substring(0, 199)
	);
	recordSpan(transaction, initialSpanDescription);
	return transaction;
}

export function finishErrorTransaction(transaction: Transaction, finalSpan: string): void {
	recordSpan(transaction, finalSpan);
	transaction.finish();
}

export enum SentryTags {
	INTERACTIONID = 'interaction_id',
	STEPTYPE = 'step_type',
	INTERACTIONTYPE = 'interaction_type',
	STEPSTART = 'step_start',
	USERATTEMPTED = 'user_attempted',
	STEPCOMPLETE = 'set_complete',
	COULDCONTINUE = 'could_continue',
	USERABANDONED = 'user_abandoned',
	// error tags
	HASUIERROR = 'has_ui_error',
	ERRORID = 'error_id',
	ERRORIDS = 'errorIds',
	ERRORNAME = 'error_name',
	ERRORDATETIME = 'error_date_time',
	ERRORMESSAGE = 'error_message',
	ERRORSTACK = 'error_stack',
	//not a tag
	RECORDSPAN = 'record_span',
}

//******************** sentry context wrapper ***************************************
export type SentryContext = {
	saveTransaction: (value: Transaction) => void;
	sentryTagAction: (type: SentryTags, value?: any) => void;
};
export const SentrySharedContext = createContext<SentryContext | undefined>(undefined);
//************************************************************************************
