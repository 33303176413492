import { useAppDispatch, useAppSelector } from '@app/hooks/useReduxToolkit';
import {
	createProviderFacility,
	editProviderFacility,
	editProviderFacilityRule,
	validateProviderFacilityRule,
} from 'state-services';
import { FacilityRuleRequest, RenderingFacility } from 'type-declarations';
import { usStates } from '@app/shared/constants';

import {
	Autocomplete,
	Button,
	CardActions,
	CardContent,
	Checkbox,
	DialogTitle,
	FormControlLabel,
	FormGroup,
	Grid2 as Grid,
	IconButton,
	MenuItem,
	TextField,
	Typography,
} from '@mui/material';
import { FC, useEffect, useRef, useState } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import { zodResolver } from '@hookform/resolvers/zod';
import { facilityFormSchema, FacilityFormData } from './validation';
import { Controller, useForm } from 'react-hook-form';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

type Props = {
	facility: RenderingFacility;
	organizationId: string;
	isCreate: boolean;
	closeDialog: () => void;
};

const EditFacility: FC<Props> = ({ facility, organizationId, isCreate, closeDialog }) => {
	const dispatch = useAppDispatch();
	const { facilities, rule, duplicateOrConflict } = useAppSelector(
		(state) => state.admin.provider.facilityRules
	);
	const states = usStates;
	const [localFacility, setLocalFacility] = useState<RenderingFacility>({} as RenderingFacility);
	const duplicateOrConflictRef = useRef(false);
	/**
	 * React Hook Form - will let us register our inputs,
	 * handle the form submission, and handle errors.
	 * https://react-hook-form.com/get-started
	 */

	const defaultValues: FacilityFormData = {
		npi: facility.npi || '',
		name: facility.name || '',
		address1: facility.address1 || '',
		city: facility.city || '',
		zip: facility.zip || '',
		state: facility.state || '',
		isEnabled: rule.isEnabled || false,
		ruleNpi: true,
		ruleName: rule?.rule?.includes('NAME') || false,
		ruleAddress: rule?.rule?.includes('ADDRESS') || false,
		destinationId: rule?.destinationId || '',
	};

	const {
		control,
		handleSubmit,
		watch,
		setValue,
		formState: { errors },
	} = useForm<FacilityFormData>({
		defaultValues,
		resolver: zodResolver(facilityFormSchema),
		mode: 'onBlur',
	});

	//Watching whole form allows for more accurate validation detection.
	const formWatch = watch();

	useEffect(() => {
		setLocalFacility(facility);
	}, []);

	// watching duplicateOrConflict
	// when validation returns false will close dialog below
	useEffect(() => {
		duplicateOrConflictRef.current = duplicateOrConflict;
	}, [duplicateOrConflict]);

	const onSubmit = (data: FacilityFormData): void => {
		const currentFacility = createFacilityRequest(data);
		const ruleRequest = createRuleRequest(data);
		if (isCreate) {
			// this will create facility and rule if needed
			dispatch(
				createProviderFacility({
					facility: currentFacility,
					createRule: formWatch.isEnabled,
					ruleRequest,
				})
			);
			closeDialog();
		} else {
			// validate will also create/update, rule or facility
			if (!shallowEquals(facility, currentFacility)) {
				dispatch(editProviderFacility({ facility: currentFacility }));
			}
			if (!!rule && isRuleChanged()) {
				dispatch(
					validateProviderFacilityRule({
						ruleRequest,
						rule,
					})
				).then(() => {
					dispatch(editProviderFacilityRule({ ruleRequest: ruleRequest }));
				});
			} else if (
				defaultValues.isEnabled !== formWatch.isEnabled ||
				defaultValues.destinationId !== formWatch.destinationId
			) {
				dispatch(editProviderFacilityRule({ ruleRequest: ruleRequest }));
			}

			//wait for validation to return dupOrConflict === false closeDialog
			const waitForValidate = setTimeout(() => {
				if (!duplicateOrConflictRef.current) {
					closeDialog();
				}
			}, 4000);
			waitForValidate;
		}
		setLocalFacility(currentFacility);
	};

	const isRuleChanged = (): boolean => {
		return (
			defaultValues.ruleNpi !== formWatch.ruleNpi ||
			defaultValues.ruleAddress !== formWatch.ruleAddress ||
			defaultValues.ruleName !== formWatch.ruleName
		);
	};

	const createFacilityRequest = (data: FacilityFormData): RenderingFacility => {
		const facilityRequest: RenderingFacility = {
			...localFacility,
			npi: data.npi,
			name: data.name.toUpperCase(),
			address1: data.address1.toUpperCase(),
			city: data.city.toUpperCase(),
			zip: data.zip,
			state: data.state,
		};
		return facilityRequest;
	};

	const createRuleRequest = (data: FacilityFormData): FacilityRuleRequest => {
		let request = {
			name: data.name.toUpperCase(),
			address1: data.address1.toUpperCase(),
			city: data.city.toUpperCase(),
			npi: data.npi,
			state: data.state,
			zip: data.zip,
			facilityId: facility.panProviderId,
			destinationfacilityId: data.destinationId,
			isEnabled: data.isEnabled,
			organizationId: organizationId,
		} as FacilityRuleRequest;

		if (!formWatch.ruleName) {
			request = { ...request, name: '' };
		}

		if (!formWatch.ruleAddress) {
			request = { ...request, address1: '', city: '', zip: '', state: '' };
		}
		return request;
	};

	const handleDeleteClicked = (): void => {
		dispatch(editProviderFacility({ facility: { ...localFacility, isDeleted: true } }));
		closeDialog();
	};

	const shallowEquals = (obj1: object, obj2: object): boolean => {
		return (
			Object.keys(obj1).length === Object.keys(obj2).length &&
			(Object.keys(obj1) as (keyof typeof obj1)[]).every((key) => {
				return Object.prototype.hasOwnProperty.call(obj2, key) && obj1[key] === obj2[key];
			})
		);
	};

	const handleOnBlur = (input: string, fieldName: string): void => {
		setValue(fieldName as keyof FacilityFormData, input.trim(), { shouldValidate: true });
	};

	return (
		<>
			<DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>
				<Typography color="primary" sx={{ fontSize: '2rem' }}>
					{isCreate ? 'Add' : 'Edit'} Facility
				</Typography>
				<IconButton onClick={() => closeDialog()} aria-label="close-dialog">
					{' '}
					<ClearIcon />
				</IconButton>
			</DialogTitle>
			<form onSubmit={handleSubmit(onSubmit)}>
				<CardContent
					sx={{
						display: 'flex',
						flexDirection: 'column',
						width: '100%',
						justifyContent: 'space-between',
					}}
				>
					<Controller
						name="npi"
						control={control}
						render={({ field }) => (
							<TextField
								{...field}
								label="NPI"
								error={!!errors.npi}
								helperText={errors.npi?.message}
								sx={{ mb: 1 }}
								onBlur={(e) => handleOnBlur(e.target.value, 'npi')}
							/>
						)}
					/>
					<Controller
						name="name"
						control={control}
						render={({ field }) => (
							<TextField
								{...field}
								label="Name"
								error={!!errors.name}
								helperText={errors.name?.message}
								sx={{ mb: 1 }}
								value={field.value.toUpperCase()}
								onChange={(e) => field.onChange(e.target.value.toUpperCase())}
								onBlur={(e) => handleOnBlur(e.target.value, 'name')}
							/>
						)}
					/>
					<Grid container spacing={2} mb={1}>
						<Grid size={{ xs: 6 }}>
							<Controller
								name="address1"
								control={control}
								render={({ field }) => (
									<TextField
										{...field}
										fullWidth
										label="Street"
										error={!!errors.address1}
										helperText={errors.address1?.message}
										value={field.value.toUpperCase()}
										onChange={(e) => field.onChange(e.target.value.toUpperCase())}
										onBlur={(e) => handleOnBlur(e.target.value, 'address1')}
									/>
								)}
							/>
						</Grid>
						<Grid size={{ xs: 6 }}>
							<Controller
								name="city"
								control={control}
								render={({ field }) => (
									<TextField
										{...field}
										fullWidth
										label="City"
										error={!!errors.city}
										helperText={errors.city?.message}
										value={field.value.toUpperCase()}
										onChange={(e) => field.onChange(e.target.value.toUpperCase())}
										onBlur={(e) => handleOnBlur(e.target.value, 'city')}
									/>
								)}
							/>
						</Grid>
					</Grid>
					<Grid container spacing={2}>
						<Grid size={{ xs: 6 }}>
							<Controller
								name="zip"
								control={control}
								render={({ field }) => (
									<TextField
										{...field}
										fullWidth
										label="Zip"
										error={!!errors.zip}
										helperText={errors.zip?.message}
										onBlur={(e) => handleOnBlur(e.target.value, 'zip')}
									/>
								)}
							/>
						</Grid>
						<Grid size={{ xs: 6 }}>
							<Controller
								name="state"
								control={control}
								render={({ field }) => (
									<TextField
										{...field}
										fullWidth
										label="State"
										select
										error={!!errors.state}
										helperText={errors.state?.message}
										// eslint-disable-next-line react/no-children-prop
										children={
											states.map((option) => (
												<MenuItem key={option.value} value={option.value}>
													{option.label.toUpperCase()}
												</MenuItem>
											)) || ''
										}
									/>
								)}
							/>
						</Grid>
					</Grid>
					<br />
					<Typography variant="h6" fontWeight={700} color="primary">
						Business Rules
					</Typography>
					<FormGroup>
						<Grid container>
							<Grid size={{ xs: 12 }}>
								<Controller
									name="isEnabled"
									control={control}
									render={({ field: { value, ref, ...field } }) => (
										<FormControlLabel
											control={
												<Checkbox
													{...field}
													inputRef={ref}
													checked={!!value}
													color="primary"
													disableRipple
												/>
											}
											label="When a PA request from this rendering facility
											is sent to Rhyme with the following criteria:"
										/>
									)}
								/>
							</Grid>
							<br />
							<Grid>
								<Controller
									name="ruleNpi"
									control={control}
									render={({ field: { value, ref, ...field } }) => (
										<FormControlLabel
											disabled
											control={
												<Checkbox
													{...field}
													inputRef={ref}
													checked={!!value}
													color="primary"
													disableRipple
												/>
											}
											label="NPI"
										/>
									)}
								/>
								<Controller
									name="ruleName"
									control={control}
									render={({ field: { value, ref, ...field } }) => (
										<FormControlLabel
											disabled={!formWatch.isEnabled}
											control={
												<Checkbox
													{...field}
													inputRef={ref}
													checked={!!value}
													color="primary"
													disableRipple
												/>
											}
											label="Name"
										/>
									)}
								/>
								<Controller
									name="ruleAddress"
									control={control}
									render={({ field: { value, ref, ...field } }) => (
										<FormControlLabel
											disabled={!formWatch.isEnabled}
											control={
												<Checkbox
													{...field}
													inputRef={ref}
													checked={!!value}
													color="primary"
													disableRipple
												/>
											}
											label="Address"
										/>
									)}
								/>
							</Grid>
							<br />
							<Grid size={{ xs: 12 }} mr={1}>
								<Controller
									control={control}
									name="destinationId"
									render={({ field: { value, onChange } }) => {
										const selectedOption = value
											? facilities.find((o) => o.panProviderId === value)
											: null;
										return (
											<Autocomplete
												options={facilities}
												onChange={(_e: unknown, data: any | null) => {
													onChange(data.panProviderId);
												}}
												disabled={!formWatch.isEnabled}
												disableClearable
												value={selectedOption}
												getOptionLabel={(option) =>
													option.npi! + ', ' + option.name! + ', ' + option.formattedAddress
												}
												renderInput={(params) => (
													<TextField
														{...params}
														label="Change the rendering facility details to: "
														value={selectedOption}
														error={!!errors.isEnabled}
														helperText={errors.isEnabled?.message}
														slotProps={{
															inputLabel: { shrink: true },
															input: { ...params.InputProps },
														}}
													/>
												)}
											/>
										);
									}}
								/>
							</Grid>
						</Grid>
					</FormGroup>
				</CardContent>
				<CardActions sx={{ mb: 2 }}>
					<Button
						disabled={shallowEquals(defaultValues, formWatch) || Object.keys(errors).length > 0}
						type="submit"
						sx={{ mr: 2, ml: 1 }}
						startIcon={<CheckCircleIcon />}
					>
						Save Changes
					</Button>
					<Button
						disabled={isCreate}
						onClick={() => {
							handleDeleteClicked();
						}}
					>
						Delete
					</Button>
				</CardActions>
			</form>
		</>
	);
};

export default EditFacility;
