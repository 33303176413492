import { useAppSelector } from '@app/hooks/useReduxToolkit';
import { selectUserFullName, updateActiveEntity, UserState } from 'state-services';
import {
	Avatar,
	Card,
	CardContent,
	CardHeader,
	Divider,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
} from '@mui/material';
import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import SelectOrganizationMenu from './SelectOrganizationMenu';
import { useNavigate } from 'react-router-dom';
import { SessionTimer } from 'rhyme-component-library';
import SecurityMenuDialog from './SecurityMenuDialog';
import { stringAvatar } from 'acessibility-helpers/src/uiHelpers';
import { isStatusIdleOrPending } from 'slice-utilities';
import { signOut as SignOut } from 'aws-amplify/auth';

const UserCard: FC = () => {
	const dispatch = useDispatch();
	const userState = useAppSelector<UserState>((state) => state.user);
	const { activeEntity } = userState;
	const userFullName = selectUserFullName(userState);
	let { name: partnerEntityName } = activeEntity;
	const navigate = useNavigate();
	const { partners, status: partnerStatus } = useAppSelector((state) => state.partner);

	const signOut = (): void => {
		SessionTimer.stopSessionTimer();
		navigate('/');
		SignOut();
	};

	useEffect(() => {
		if (!isStatusIdleOrPending([partnerStatus]) && partners.length > 0) {
			dispatch(updateActiveEntity(activeEntity));
		}
	}, []);

	if (partnerEntityName.length >= 15) {
		partnerEntityName = partnerEntityName.substring(0, 17) + '...';
	}

	return (
		<Card variant="outlined">
			<CardHeader
				avatar={<Avatar {...stringAvatar(userFullName)} />}
				title={userFullName}
				subheader={partnerEntityName}
			/>
			<Divider />
			<CardContent sx={{ padding: 0 }}>
				<List dense>
					<SelectOrganizationMenu></SelectOrganizationMenu>
					<SecurityMenuDialog />
					<ListItem>
						<ListItemButton onClick={signOut} dense>
							<ListItemText>Sign Out</ListItemText>
						</ListItemButton>
					</ListItem>
				</List>
			</CardContent>
		</Card>
	);
};

export default UserCard;
