import { UserCapabilities } from './constants';

export const hasCapabilityPermission = (
	capabilities: string[] | undefined,
	expectedCapabilities: UserCapabilities[]
): boolean => {
	if (capabilities === undefined) {
		return false;
	}

	return expectedCapabilities.every((expectedCap) => capabilities.includes(expectedCap));
};
