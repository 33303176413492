import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import AdminUsers from './AdminUsers';
import DefaultContact from './provider/defaultContact/DefaultContact';
import FacilityRules from './provider/FacilityRules/FacilityRules';
import { AdminOption } from './types';
import UserRoleChips from './components/UserRoleChips';

export const payerAdminTabOptions: AdminOption[] = [
	{
		text: 'Users',
		path: '/admin/users',
		component: <AdminUsers />,
	},
];

export const providerAdminTabOptions: AdminOption[] = [
	{
		text: 'Users',
		path: '/admin/users',
		component: <AdminUsers />,
	},
	{
		text: 'Default Contact',
		path: '/admin/default-contact',
		component: <DefaultContact />,
	},
	{
		text: 'Facility Rules',
		path: '/admin/facility-rules',
		component: <FacilityRules />,
	},
];

export const userTableColumns: GridColDef[] = [
	{
		field: 'fullName',
		headerName: 'Name',
		description: "The user's first and last name.",
		sortable: true,
		minWidth: 180,
		flex: 1,
		valueGetter: (_value, row) => `${row.firstName || ''} ${row.lastName || ''}`,
	},
	{
		field: 'userName',
		headerName: 'Email Address',
		description: "The user's email address.",
		sortable: true,
		minWidth: 180,
		flex: 1,
		valueGetter: (_value, row) => `${row.userName || ''}`,
	},
	{
		field: 'role',
		headerName: 'Role(s)',
		description:
			'Roles determine what information a user can access and what actions they can take.',
		minWidth: 400,
		flex: 1,
		filterable: false,
		sortable: false,
		renderCell: (params: GridRenderCellParams) => <UserRoleChips user={params.row} />,
	},
];
