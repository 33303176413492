import { createSlice } from '@reduxjs/toolkit';
import { ExtendedFileData, SliceStatus, DocumentType, FileDataStatus } from 'type-declarations';
import { uploadClinicalInformation } from './thunks';
import { selectClinicalNoteObject } from './selectors';

export interface FileUploadState {
	status: SliceStatus;
	clinicalInformation: Array<ExtendedFileData>;
}

export const initialClinicalNote: ExtendedFileData = {
	documentType: DocumentType.ClinicalNote,
	fileId: '',
	name: 'Clinical Note',
	status: FileDataStatus.INITIAL,
	uri: '',
};

const initialState: FileUploadState = {
	status: SliceStatus.IDLE,
	clinicalInformation: [{ ...initialClinicalNote }],
};

export const fileUploadSlice = createSlice({
	name: 'file-upload',
	initialState,
	reducers: {
		statusUpdate: (state, action) => {
			state.status = action.payload;
		},
		resetFileUpload: (state) => {
			Object.assign(state, initialState);
		},
		addClinicalDocuments: (state, action) => {
			const files = action.payload as File[];
			const extendedFileDataArray: Array<ExtendedFileData> = files.map((f) => {
				return {
					fileId: '',
					name: f.name,
					documentType: DocumentType.ClinicalDocument,
					status: f.size <= 0 ? FileDataStatus.ZERO_BYTE : FileDataStatus.INITIAL,
					systemFile: f,
					systemFileSize: f.size,
					uri: '',
				};
			});
			state.clinicalInformation.push(...extendedFileDataArray);
		},
		setClinicalNote: (state, action) => {
			const newClinicalNoteText = action.payload as string;
			const clinicalNoteData = selectClinicalNoteObject(state);
			if (clinicalNoteData) {
				clinicalNoteData.clinicalNoteText = newClinicalNoteText;
			} else {
				const newClinicalNoteData: ExtendedFileData = {
					...initialClinicalNote,
					clinicalNoteText: newClinicalNoteText,
				};
				state.clinicalInformation.push(newClinicalNoteData);
			}
		},
		removeClinicalInformation: (state, action) => {
			const fileToRemove = action.payload as ExtendedFileData;
			state.clinicalInformation = state.clinicalInformation.filter(
				(doc) => doc.name !== fileToRemove.name
			);
		},
	},
	extraReducers: (builder) => {
		builder.addCase(uploadClinicalInformation.pending, (state) => {
			state.status = SliceStatus.PENDING;
		});
		builder.addCase(uploadClinicalInformation.fulfilled, (state, action) => {
			state.status = SliceStatus.SUCCEEDED;
			state.clinicalInformation = action.payload as Array<ExtendedFileData>;
		});
		builder.addCase(uploadClinicalInformation.rejected, (state, action) => {
			state.status = SliceStatus.REJECTED;
			state.clinicalInformation = action.payload as Array<ExtendedFileData>;
		});
	},
});

export const fileUploadReducer = fileUploadSlice.reducer;
export const fileUploadActions = fileUploadSlice.actions;
export const {
	statusUpdate,
	resetFileUpload,
	addClinicalDocuments,
	setClinicalNote,
	removeClinicalInformation,
} = fileUploadActions;
