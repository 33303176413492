import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getCapabilities } from 'user-utilities';
import { Entity, IdTokenPayload, PartnerEntity, SliceStatus } from 'type-declarations';
import { fetchUserRoles, fetchAndSetIdToken } from './';
import { getIdTokenPayloadFromIdTokenJwt } from './utilities';
import * as Sentry from '@sentry/react';

export const userInitialState: UserState = {
	userDetails: {} as IdTokenPayload,
	activeEntity: {} as PartnerEntity,
	entities: [],
	idToken: '',
	isRso: false,
	status: SliceStatus.IDLE,
};

export interface UserState {
	userDetails: IdTokenPayload;
	activeEntity: PartnerEntity;
	entities: Entity[];
	idToken: string;
	isRso: boolean; // rso signin
	status: SliceStatus;
}

function setUserStateWithIdToken(state: UserState, idToken: string): void {
	state.idToken = idToken;
	const jwtBody = getIdTokenPayloadFromIdTokenJwt(state.idToken);
	state.userDetails = jwtBody;
	Sentry.setUser({
		email: jwtBody.email,
		give_name: jwtBody.given_name,
		last_name: jwtBody.family_name,
		full_name: `${jwtBody.given_name} ${jwtBody.family_name}`,
	});
}

export const userSlice = createSlice({
	name: 'user',
	initialState: userInitialState,
	reducers: {
		logOut: (state: UserState) => {
			Object.assign(state, userInitialState);
		},
		updateActiveEntity: (state: UserState, action) => {
			const capabilities = getCapabilities(state.entities, action.payload.id);
			state.activeEntity = { ...action.payload, capabilities };
			Sentry.setTag('orgID', state.activeEntity.id);
			Sentry.setTag('activePartnerEntityId', state.activeEntity.id);
			Sentry.setTag('activePartnerEntityType', state.activeEntity.partnerType);
		},
		isRsoUser: (state: UserState, action: PayloadAction<boolean>) => {
			state.isRso = action.payload;
		},
		setIdToken: (state: UserState, action: PayloadAction<string>) => {
			setUserStateWithIdToken(state, action.payload);
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchUserRoles.pending, (state) => {
			if (state.status === SliceStatus.IDLE) {
				state.status = SliceStatus.PENDING;
			} else state.status = SliceStatus.REFRESHING;
		}),
			builder.addCase(fetchUserRoles.fulfilled, (state, action: PayloadAction<Entity[]>) => {
				state.status = SliceStatus.SUCCEEDED;
				state.entities = action.payload;
				state.isRso = false;
			});
		builder.addCase(fetchUserRoles.rejected, (state) => {
			state.status = SliceStatus.REJECTED;
		});
		builder.addCase(fetchAndSetIdToken.rejected, (state) => {
			state.idToken = 'ERROR';
		});
		builder.addCase(fetchAndSetIdToken.fulfilled, (state, action: PayloadAction<string>) => {
			setUserStateWithIdToken(state, action.payload);
		});
	},
});

export const userReducer = userSlice.reducer;
export const userActions = userSlice.actions;
export const { logOut, updateActiveEntity, isRsoUser, setIdToken } = userActions;
