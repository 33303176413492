import {
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	LinearProgress,
	Typography,
} from '@mui/material';
import React, { FC } from 'react';
import './customMessage.css';

type Props = {
	title: string; //main message text
	subtitle?: string; // helper text
	loading?: boolean; // loading state
	primaryBtn?: string; // primary text button
	secondaryBtn?: string; //secondary text button
	primaryBtnAction?: () => void; // primary button action
	secondaryBtnAction?: () => void; // secondary button action
};

/**
 * this component can be used for:
 * static message
 * message with a action
 * loading state
 */
export const CustomMessage: FC<Props> = ({
	title,
	subtitle,
	loading,
	primaryBtn,
	secondaryBtn,
	primaryBtnAction,
	secondaryBtnAction,
}) => {
	return (
		<Box
			width="100%"
			height="100%"
			display="flex"
			justifyContent="center"
			alignContent="center"
			alignItems="center"
			className="customMessage"
		>
			<Card sx={{ maxWidth: 450, minWidth: 400, maxHeight: 300, minHeight: 100 }}>
				<CardContent>
					<Typography variant="h5" component="h5" color="primary">
						{title}
					</Typography>
					<p>{subtitle}</p>
					{loading && <LinearProgress />}
				</CardContent>
				{primaryBtn && (
					<CardActions>
						<Button onClick={primaryBtnAction}>{primaryBtn}</Button>
						{secondaryBtn && secondaryBtnAction && (
							<Button onClick={secondaryBtnAction}>{secondaryBtn}</Button>
						)}
					</CardActions>
				)}
			</Card>
		</Box>
	);
};
