import 'symbol-observable';
import React, { FC } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import {
	RouterProvider,
	createRoutesFromChildren,
	matchRoutes,
	useLocation,
	useNavigationType,
} from 'react-router-dom';
import store from '@app/store';
import { Provider as ReactReduxProvider } from 'react-redux';
import { router } from '@core/router';
import theme from './app/shared/themes/theme';
import './index.scss';
import { Amplify } from 'aws-amplify';
import { RhymeAuthenticator, authRules, awsConfig } from 'rhyme-component-library';
import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';
import { fetchAuthSession } from 'aws-amplify/auth';
import { setIdToken } from 'state-services';

Amplify.configure(awsConfig, authRules);

const browserTracing = new Sentry.BrowserTracing({
	routingInstrumentation: Sentry.reactRouterV6Instrumentation(
		React.useEffect,
		useLocation,
		useNavigationType,
		createRoutesFromChildren,
		matchRoutes
	),
});
browserTracing.options.markBackgroundTransactions = false;

Sentry.init({
	dsn: 'https://c2f7cba7ae4047518387ffde96bc34bc@o516067.ingest.sentry.io/4505399494836225',
	integrations: [browserTracing, new Sentry.Replay()],
	release: process.env.REACT_APP_ADO_BUILD_NUMBER,
	environment: process.env.REACT_APP_SENTRY_ENV,
	tracesSampleRate: 1.0,
	replaysSessionSampleRate: 1.0,
	replaysOnErrorSampleRate: 1.0,
});

export const setupUserTokenRefreshTimer = (): void => {
	setInterval(async () => {
		try {
			const authSession = await fetchAuthSession();
			const idToken = authSession.tokens?.idToken?.toString() ?? '';
			store.dispatch(setIdToken(idToken));
		} catch (err) {
			// eslint-disable-next-line no-console
			console.warn(err);
		}
	}, 5 * 60000); //TODO: Dynamically set refresh time
};

setupUserTokenRefreshTimer();

export const Index: FC = () => {
	return (
		<React.StrictMode>
			<ThemeProvider theme={theme}>
				<RhymeAuthenticator>
					<RouterProvider
						router={router}
						future={{
							//docs https://reactrouter.com/en/6.28.0/upgrading/future#v7_starttransition
							v7_startTransition: true,
						}}
					/>
				</RhymeAuthenticator>
			</ThemeProvider>
		</React.StrictMode>
	);
};

const container = document.getElementById('app');
const root = createRoot(container!);
root.render(
	//ReactReduxProvider (called just "Provider" in the react-redux library) is the name of the
	//application wrapping element used to declare the store through your application. "Provider" in
	//this context has nothing to do with the business idea of "Provider" used in the rest of this
	//application
	<ReactReduxProvider store={store}>
		<Index />
	</ReactReduxProvider>
);
