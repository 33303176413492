import { UserState } from './slice';

//Selectors for UserState
//Selectors concepts explained here: https://redux.js.org/usage/deriving-data-selectors#calculating-derived-data-with-selectors

/**
 * Returns the user's full name by concatenating the user's given name
 * and family name found in their ID token.
 */
export const selectUserFullName = (state: UserState): string => {
	if (state.userDetails && state.userDetails.given_name && state.userDetails.family_name) {
		return `${state.userDetails.given_name} ${state.userDetails.family_name}`;
	}
	return '';
};
