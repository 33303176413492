import {
	Dialog,
	DialogTitle,
	IconButton,
	ListItemButton,
	ListItemText,
	ListItem,
	DialogContent,
} from '@mui/material';
import { useEffect, FC, useState } from 'react';
import { getTotpSeedCode } from 'user-utilities';
import ClearIcon from '@mui/icons-material/Clear';
import { fetchMFAPreference } from 'aws-amplify/auth';
import SecurityMenu from './SecurityMenu';

const SecurityMenuDialog: FC = () => {
	const [open, setOpen] = useState(false);
	const [preferredMfa, setPreferredMfa] = useState('DISABLED');
	const [seedCode, setSeedCode] = useState('NOCODE');

	const getSeed = async (): Promise<string> => {
		setSeedCode(await getTotpSeedCode());
		return seedCode;
	};

	const getPreferredMfa = async (): Promise<void> => {
		const mfaPreference = await fetchMFAPreference();
		setPreferredMfa(mfaPreference.preferred?.toString() ?? 'DISABLED');
	};

	useEffect(() => {
		getPreferredMfa();
		getSeed();
	}, []);

	return (
		<>
			<ListItem>
				<ListItemButton
					id="basic-button"
					aria-controls={open ? 'mfa-settings-menu' : undefined}
					aria-haspopup="true"
					aria-expanded={open ? 'true' : undefined}
					onClick={(): void => setOpen(true)}
				>
					<ListItemText>MFA Settings</ListItemText>
				</ListItemButton>
			</ListItem>
			<Dialog open={open}>
				<DialogTitle
					sx={{
						justifyContent: 'space-between',
						display: 'flex',
						minWidth: 300, // keeps whole dialog from being too small
					}}
				>
					MFA Settings
					<IconButton onClick={(): void => setOpen(false)} aria-label="close-dialog">
						<ClearIcon />
					</IconButton>
				</DialogTitle>
				<DialogContent>
					<SecurityMenu
						preferredMfa={preferredMfa}
						seedCode={seedCode}
						setOpen={setOpen}
						setPreferredMfa={setPreferredMfa}
					/>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default SecurityMenuDialog;
