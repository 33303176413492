import { createAsyncThunk } from '@reduxjs/toolkit';
import { get } from 'aws-amplify/api';
import { RHYME_API_SERVICES, User, Entity } from 'type-declarations';
import { filterUserRoles, UserRolesFilter } from './filterUserRoles';
import { getEntitiesFromUserRoles, getActiveEntityDetails } from './utilities';
import { fetchPartners } from '../partner/thunks';
import { fetchAuthSession } from 'aws-amplify/auth';

const rhymeApiUrl = RHYME_API_SERVICES.RhymeApi;

export const fetchAndSetIdToken = createAsyncThunk<string>(
	'user/fetch_and_set_id_token',
	async (): Promise<string> => {
		const authSession = await fetchAuthSession();
		return authSession.tokens?.idToken?.toString() ?? '';
	}
);

export const fetchUserRoles = createAsyncThunk(
	'user/fetch_user_roles',
	async (userRolesFilters: UserRolesFilter[], { dispatch }): Promise<Entity[]> => {
		const { body } = await get({
			apiName: rhymeApiUrl,
			path: `authorization/user`,
		}).response;
		const data = (await body.json()) as User;
		filterUserRoles(data, userRolesFilters);
		const entities = getEntitiesFromUserRoles(data.userRoles);
		const activeEntityDetails = getActiveEntityDetails(entities);
		const { activePartnerEntityId } = activeEntityDetails;

		if (entities.length > 0) {
			dispatch(
				fetchPartners({
					partnerIds: entities.map((e) => e.entityId),
					activeEntityId: activePartnerEntityId,
				})
			);
		}
		return entities;
	}
);
