import { useAppDispatch, useAppSelector } from '@app/hooks/useReduxToolkit';
import { Autocomplete, TextField } from '@mui/material';
import { FC } from 'react';
import { getLighthouseEmbedUrl } from 'state-services';
import { EntityType, PartnerEntity } from 'type-declarations';

interface Props {
	partners: PartnerEntity[];
	disabled: boolean;
}

const LighthouseOrganizationSelect: FC<Props> = ({ partners, disabled }) => {
	const dispatch = useAppDispatch();
	const { selectedPartnerEntities } = useAppSelector((state) => state.lighthouse);
	const { activeEntity } = useAppSelector((state) => state.user);

	const providerOrganizations = partners.filter(
		(p) => p.partnerType === EntityType.PROVIDER_ORGANIZATION && p.isLighthouseEnabled
	);

	const handleSelectedOrgs = (partnerEntities: Array<PartnerEntity>): void => {
		if (partnerEntities.length >= 1) {
			dispatch(getLighthouseEmbedUrl({ activeEntity, selectedPartnerEntities: partnerEntities }));
		}
	};

	return (
		<Autocomplete
			disabled={disabled}
			multiple
			fullWidth
			size="small"
			options={providerOrganizations}
			value={selectedPartnerEntities}
			onChange={(_, newValue) => {
				handleSelectedOrgs([...newValue]);
			}}
			getOptionLabel={(option) => option.name}
			isOptionEqualToValue={(o, v) => {
				return o.id === v.id;
			}}
			renderInput={(params) => (
				<TextField {...params} label="Organizations included in reporting" />
			)}
			sx={{ marginBottom: 1 }}
		/>
	);
};

export default LighthouseOrganizationSelect;
