import { getEntityTypeFromScopeKey } from 'user-utilities';
import { Entity, EntityType, IdTokenPayload, ScopeKeyOptions, UserRole } from 'type-declarations';

type ActiveEntityDetails = {
	activePartnerEntityId: string;
	partnerEntityType: string;
};

export const getIdTokenPayloadFromIdTokenJwt = (idTokenJwt: string): IdTokenPayload => {
	if (!idTokenJwt) return {} as IdTokenPayload;
	try {
		const sections = idTokenJwt.split('.');
		const idTokenPayload = JSON.parse(atob(sections[1])) as IdTokenPayload;
		return idTokenPayload;
	} catch {
		return {} as IdTokenPayload;
	}
};

/**
 * Accepts a user's list of UserRoles and combines them into an array of Entities. The returned
 * Entity array is used to control external-facing applications so that the user can switch between
 * entities relevant to the application.
 */
export const getEntitiesFromUserRoles = (userRoles: UserRole[]): Entity[] => {
	const result = userRoles.reduce((entityList: Entity[], currentRole: UserRole) => {
		const scopeKey = Object.keys(currentRole.scope)[0];
		const scopeValue = currentRole.scope[scopeKey];
		const existingEntity = entityList.find(
			(e) => e.scopeKey === scopeKey && e.entityId === scopeValue
		);

		if (existingEntity) {
			existingEntity.roles.push(currentRole.name);
			const set = new Set([...existingEntity.capabilities, ...currentRole.capabilities]);
			existingEntity.capabilities = Array.from(set);
		} else {
			entityList.push({
				entityId: scopeValue,
				roles: [currentRole.name],
				scopeKey: scopeKey,
				capabilities: currentRole.capabilities,
			} as Entity);
		}
		return entityList;
	}, []);
	return result;
};

/**
 * If the current URL path contains information about what Partner Entity the user is viewing something as,
 * use that Partner Entity as the active entity.
 * Currently only relevant for Provider Organizations in react-tachyon.
 */
export const getActiveEntityFromPath = (windowLocation: Location): ActiveEntityDetails | null => {
	const pathParts = windowLocation.pathname?.split('/') ?? [];
	const indexOfOrganization = pathParts.indexOf('organization');
	if (indexOfOrganization > -1) {
		//pattern is organization/1234567890 -- so the orgId will immediately
		//follow the string "organization" in the split array
		const orgIdFromRoute = pathParts[indexOfOrganization + 1];
		if (orgIdFromRoute && orgIdFromRoute.length === 10) {
			return {
				activePartnerEntityId: orgIdFromRoute,
				partnerEntityType: EntityType.PROVIDER_ORGANIZATION,
			};
		}
	}
	return null;
};

/**
 * @return activePartnerEntityId: string (first payer institution || first provider organization || first entity)
 * @return partnerEntityType: partner type for the activePartnerEntityId selected
 */
export const getActiveEntityDetails = (entities: Entity[]): ActiveEntityDetails => {
	try {
		const activeEntityFromPath = getActiveEntityFromPath(window.location);
		if (activeEntityFromPath) {
			return activeEntityFromPath;
		}
	} catch {
		//left empty because this is just to enable testing
	}

	if (entities.length <= 0) {
		return { activePartnerEntityId: '', partnerEntityType: '' };
	}

	const payerInstitution = entities.find(
		(e) => e.scopeKey === ScopeKeyOptions.PAYER_INSTITUTION_ID
	);
	const providerOrg = entities.find((e) => e.scopeKey === ScopeKeyOptions.ORGANIZATION_ID);

	// choose a payer first, if none then provider org, then fall back to random first entity
	const activeEntity: ActiveEntityDetails = payerInstitution
		? {
				activePartnerEntityId: payerInstitution.entityId,
				partnerEntityType: EntityType.PAYER_INSTITUTION,
			}
		: providerOrg
			? {
					activePartnerEntityId: providerOrg.entityId,
					partnerEntityType: EntityType.PROVIDER_ORGANIZATION,
				}
			: {
					activePartnerEntityId: entities[0].entityId,
					partnerEntityType: getEntityTypeFromScopeKey(entities[0].scopeKey),
				};

	return activeEntity;
};
