import { createSlice } from '@reduxjs/toolkit';
import { lookupNpi } from './thunks';
import { NpiLookupProvider } from 'type-declarations';

export interface NpiState {
	providerCache: NpiLookupProvider[];
}

const initialState: NpiState = {
	providerCache: [] as NpiLookupProvider[],
};

export const npiSlice = createSlice({
	name: 'npi',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(lookupNpi.fulfilled, (state, action) => {
			if (action.payload && !state.providerCache.find((p) => p.npi === action.payload?.npi)) {
				state.providerCache.push(action.payload);
			}
		});
	},
});

export const npiReducer = npiSlice.reducer;
export const npiActions = npiSlice.actions;
