import { PartnerEntity } from 'services/partner/models';
import { EntityType } from './user-types';

export class UserRoleTypes {
	static ProviderAdmin = 'ProviderAdmin';
	static ProviderNoaAdmin = 'ProviderNoaAdmin';
	static ProviderReportViewer = 'ProviderReportViewer';
	static ProviderPaRequester = 'ProviderPaRequester';
	static ProviderReportViewerPhi = 'ProviderReportViewerPhi';
	static ProviderVbScorecardViewer = 'ProviderVbScorecardViewer';

	static PayerAdmin = 'PayerAdmin';
	static PayerReportViewer = 'PayerReportViewer';
	static PayerReportViewerPhi = 'PayerReportViewerPhi';
	static PayerVbScorecardViewer = 'PayerVbScorecardViewer';

	static DbmAdmin = 'DbmAdmin';
	static DbmReportViewer = 'DbmReportViewer';
	static DbmReportViewerPhi = 'DbmReportViewerPhi';
}

export interface DescriptiveRole {
	description: string;
	display: string;
	role: UserRoleTypes;
	entityType: EntityType;
	noaOnly?: boolean;
}

export const DescriptiveUserRoles: DescriptiveRole[] = [
	{
		role: UserRoleTypes.ProviderPaRequester,
		description: 'Submit requests to payers via data gathering, clinical, and survey actions.',
		display: 'Prior Auth Requester',
		entityType: EntityType.PROVIDER_ORGANIZATION,
	},
	{
		role: UserRoleTypes.ProviderReportViewer,
		description: 'User has access to view reports.',
		display: 'Report Viewer',
		entityType: EntityType.PROVIDER_ORGANIZATION,
	},
	{
		role: UserRoleTypes.ProviderReportViewerPhi,
		description: 'User has access to view reports, including reports containing PHI.',
		display: 'PHI Report Viewer',
		entityType: EntityType.PROVIDER_ORGANIZATION,
	},
	{
		role: UserRoleTypes.ProviderAdmin,
		description: 'Access to the portal admin page.',
		display: 'Portal Admin',
		entityType: EntityType.PROVIDER_ORGANIZATION,
	},
	{
		role: UserRoleTypes.ProviderNoaAdmin,
		description: 'Access to the portal admin page.',
		display: 'Portal NOA Admin',
		entityType: EntityType.PROVIDER_ORGANIZATION,
		noaOnly: true,
	},
	{
		role: UserRoleTypes.ProviderVbScorecardViewer,
		description: 'Access to payer Lighthouse value-based deals dashboard.',
		display: 'VBPA Scorecard Viewer',
		entityType: EntityType.PROVIDER_ORGANIZATION,
	},
	{
		role: UserRoleTypes.PayerAdmin,
		description: 'Access to the portal admin page.',
		display: 'Portal Admin',
		entityType: EntityType.PAYER_INSTITUTION,
	},
	{
		role: UserRoleTypes.PayerReportViewer,
		description: 'User has access to view reports.',
		display: 'Report Viewer',
		entityType: EntityType.PAYER_INSTITUTION,
	},
	{
		role: UserRoleTypes.PayerReportViewerPhi,
		description: 'User has access to view reports, including reports containing PHI.',
		display: 'PHI Report Viewer',
		entityType: EntityType.PAYER_INSTITUTION,
	},
	{
		role: UserRoleTypes.PayerVbScorecardViewer,
		description: 'Access to payer Lighthouse value-based deals dashboard.',
		display: 'VBPA Scorecard Viewer',
		entityType: EntityType.PAYER_INSTITUTION,
	},
	{
		role: UserRoleTypes.DbmAdmin,
		description: 'Access to the portal admin page.',
		display: 'Portal Admin',
		entityType: EntityType.DELEGATED_BENEFIT_MANAGER,
	},
	{
		role: UserRoleTypes.DbmReportViewer,
		description: 'User has access to view reports.',
		display: 'Report Viewer',
		entityType: EntityType.DELEGATED_BENEFIT_MANAGER,
	},
	{
		role: UserRoleTypes.DbmReportViewerPhi,
		description: 'User has access to view reports, including reports containing PHI.',
		display: 'PHI Report Viewer',
		entityType: EntityType.DELEGATED_BENEFIT_MANAGER,
	},
];

export const getDescriptiveRolesByPartnerEntity = (entity: PartnerEntity): DescriptiveRole[] => {
	let options = DescriptiveUserRoles.filter((dr) => dr.entityType === entity.partnerType);
	if (!entity.noticeOfAdmissionEnabled) {
		options = options.filter((o) => !o.noaOnly);
	}

	return options;
};

export const getDescriptiveRolesByRoleStrings = (roleStrings: string[]): DescriptiveRole[] => {
	return DescriptiveUserRoles.filter((dr) => roleStrings.includes(dr.role as string)) || [];
};
