import { FC, ReactNode } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import {
	Box,
	Drawer,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	ListItem,
} from '@mui/material';
import LogoBanner from '@assets/branding/Rhyme_Lock_Up_Full_Color_RGB.svg';
import './styles.scss';
import InsightsIcon from '@mui/icons-material/Insights';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import UserCard from './UserCard';
import { useAppSelector } from '@app/hooks/useReduxToolkit';
import { hasCapabilityPermission } from '@app/shared/permissions';
import { useTheme } from '@mui/material/styles';
import { UserCapabilities } from '@app/shared/constants';
import { EXTERNAL_ROUTES } from 'type-declarations';

const drawerWidth = 240;
type page = {
	text: string;
	icon: ReactNode;
	path: string;
	matchPath?: string;
	visible: boolean;
	isSelected: boolean;
};
const SidebarNav: FC = () => {
	const { activeEntity } = useAppSelector((state) => state.user);
	const navigate = useNavigate();
	const currentPath = useLocation().pathname;
	const theme = useTheme();
	const { capabilities } = activeEntity;

	const handleNavigation = (page: page): void => {
		if (page.text.includes('Case Search')) {
			window.location.href = page.path;
		} else {
			navigate(page.path);
		}
	};

	const pages: Array<page> = [
		{
			text: 'Lighthouse',
			icon: <InsightsIcon />,
			path: '/lighthouse',
			visible:
				hasCapabilityPermission(capabilities, [UserCapabilities.GetLighthouseDashboard]) &&
				activeEntity.isLighthouseEnabled,
			isSelected: false,
		},
		{
			text: 'Case Search',
			icon: <ContentPasteSearchIcon />,
			path: EXTERNAL_ROUTES.ReactTachyon,
			visible: hasCapabilityPermission(capabilities, [UserCapabilities.GetCase]),
			isSelected: false,
		},
		{
			text: 'Admin',
			icon: <AdminPanelSettingsIcon />,
			path: '/admin/users',
			matchPath: '/admin',
			visible: hasCapabilityPermission(capabilities, [
				UserCapabilities.GrantUserRole,
				UserCapabilities.GetUser,
			]),
			isSelected: false,
		},
	];
	pages.forEach((page) => (page.isSelected = currentPath.includes(page.matchPath ?? page.path)));

	return (
		<Drawer
			className="rhyme-navbar"
			variant="permanent"
			anchor="left"
			sx={{
				width: drawerWidth,
			}}
			PaperProps={{
				sx: {
					backgroundColor: theme.palette.grey['500'],
					color: 'grey',
					width: drawerWidth,
					boxSizing: 'border-box',
				},
			}}
		>
			<Box
				sx={{
					height: '100%',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-between',
				}}
			>
				<div>
					<Box margin={2}>
						<Link to="/">
							<img src={LogoBanner} alt="Return to Home" width="180px" />
						</Link>
					</Box>
					<Box sx={{ overflow: 'auto' }}>
						<List aria-labelledby="nested-list-subheader" sx={{ width: '100%' }} id="first-list">
							{pages
								.filter((p) => p.visible)
								.map((page, index) => {
									return (
										<ListItem key={index}>
											<ListItemButton
												selected={page.isSelected}
												onClick={() => handleNavigation(page)}
												sx={{
													fontSize: '200px',
													color: 'secondary.main',
													'&.Mui-selected': {
														bgcolor: 'primary.light',
														borderRight: `2px solid ${theme.palette.secondary.light}`,
														color: 'primary.main',
													},
												}}
											>
												<ListItemIcon
													sx={{
														color: page.isSelected ? 'primary.main' : 'secondary.main',
													}}
												>
													{page.icon}
												</ListItemIcon>
												<ListItemText primary={page.text} />
											</ListItemButton>
										</ListItem>
									);
								})}
						</List>
					</Box>
				</div>
				<Box padding={1}>
					<UserCard />
					<p>
						Rhyme Portal: {process.env.REACT_APP_ENV}-{process.env.REACT_APP_ADO_BUILD_NUMBER}
					</p>
				</Box>
			</Box>
		</Drawer>
	);
};
export default SidebarNav;
